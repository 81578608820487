import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import CommonService from "../../../services/common.service";
import { getUser } from "../../../utils/UserDetails";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";

const RegisterModal = ({ show, handleClose, userRole }) => {
  const history = useHistory();
  const location = useLocation();
  const currentUser = getUser();
  const { role } = useParams();

  const [viewLogin, setViewLogin] = useState(false);
  const [masterRoles, setMasterRoles] = useState([]);
  const [activeRole, setActiveRole] = useState(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const words = ["Company", "Recruiter", "Student", "Trainer"]; // Array of words to display
  const [typingSpeed, settypingSpeed] = useState("250");

  useEffect(() => {
    let windowview = location.pathname.split("/").pop();

    if (windowview && windowview === "register") {
      setViewLogin(false);
    }
  }, []);

  useEffect(() => {
    if (!currentUser && show) {
      getRoles();
    }
  }, [show]);

  useEffect(() => {
    const updateTypewriter = () => {
      const currentWord = words[currentWordIndex];
      let nextText = currentText;
      settypingSpeed(250);

      if (isDeleting) {
        nextText = currentWord.substring(0, nextText.length - 1);
      } else {
        nextText = currentWord.substring(0, nextText.length + 1);
      }

      if (!isDeleting && nextText === currentWord) {
        setIsDeleting(true);
        settypingSpeed(1000); // Pause after completing typing
      } else if (isDeleting && nextText === "") {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        settypingSpeed(250); // Pause before next typing
      }
      setCurrentText(nextText);
    };

    const typingTimer = setTimeout(updateTypewriter, typingSpeed);

    return () => clearTimeout(typingTimer);
  }, [currentText, currentWordIndex, isDeleting, words, typingSpeed]);

  const getRoles = async () => {
    let resultData = await CommonService.getRoles();

    if (resultData && resultData.status === 2000) {
      setMasterRoles(resultData.data);
    }
  };

  const setRolesActive = (obj, roleKey) => {
    let roles = [...masterRoles];
    setActiveRole(obj.roles_master_code);
    roles.map((v) => {
      v.isActive = false;
    });
    roles[roleKey]["isActive"] = obj.isActive ? false : true;
    setMasterRoles(roles);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal"
    >
      <div className="text-end">
        <Button variant="close" className=" fa-2x " onClick={handleClose} />
      </div>
      <Modal.Header className="d-flex mt-0 pt-0 ">
        <Modal.Title className="flex-grow-1 mt-0 pt-0 h5 ">
          {viewLogin
            ? "Kindly login to explore more !"
            : "Kindly register to explore more !"}
        </Modal.Title>
      </Modal.Header>

      <div className=" auth-container">
        {viewLogin ? (
          <Login
            setViewLogin={setViewLogin}
            masterRoles={masterRoles}
            activeRole={activeRole}
            setMasterRoles={setMasterRoles}
            role={role}
            modal={true}
          />
        ) : (
          <Register
            setViewLogin={setViewLogin}
            masterRoles={masterRoles}
            activeRole={activeRole}
            setRolesActive={setRolesActive}
            setMasterRoles={setMasterRoles}
            role={role}
            userRole={userRole}
          />
        )}
      </div>
    </Modal>
  );
};

export default RegisterModal;
