import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode"; // For decoding Google JWT token

import AuthService from "../../services/auth.service";
import {
  getUser,
  getUserRole,
  setRefreshToken,
  setUserData,
  setUserMenus,
  setUserPlan,
  setUserRole,
  setUserSession,
} from "../../utils/UserDetails";
import Axios from "../../config/axios";

const GoogleLoginComponent = (props) => {
  const [activeRole, setActiveRole] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setActiveRole(props.activeRole);

    if (props.activeRole) {
      googleRegister();
    }
  }, [props.activeRole]);

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      const decoded = jwt_decode(credentialResponse.credential);

      const googleData = {
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        email: decoded.email,
        provider: "google",
        provider_id: decoded.sub,
        token: credentialResponse.credential,
        refresh_token: decoded.jti,
        expires_in: decoded.exp,
      };

      if (googleData.email) {
        register(googleData);
      } else {
        toast.error("Please try again!");
      }
    } catch (error) {
      toast.error("Failed to process Google response.");
    }
  };

  const register = async (googleData) => {
    let emailData = {
      email: googleData.email,
    };

    let userData = await AuthService.checkUserExist(emailData);

    if (userData.status === true) {
      let resultData = await AuthService.googleLogin(googleData);

      if (resultData?.status && resultData.status === true) {
        Axios.interceptors.request.use(function (config) {
          config.headers["Authorization"] = resultData?.data?.api_token
            ? `Bearer ${resultData.data.api_token}`
            : "";
          return config;
        });

        let userData = resultData?.data ? resultData.data : "";
        setUserData(userData);
        setUserSession(userData?.api_token);
        setUserRole(userData?.user_role);
        setUserMenus(userData?.menus);
        setUserPlan(userData?.plan);
        setRefreshToken(userData?.refresh_token);
        // setUserSession(resultData.data);

        if (getUser()) {
          setTimeout(() => {
            history.push("/");
          }, 500);
        }
      } else {
        toast.error("Please Try Agian!");
      }
    } else {
      googleRegister(googleData);
    }
  };

  const googleRegister = async (googleData) => {
    if (googleData) {
      googleData.role_type = props.activeRole || "STUDENT";

      let resultData = await AuthService.googleRegister(googleData);

      if (resultData?.status && resultData.status === true) {
        let userData = resultData?.data ? resultData.data : "";
        setUserData(userData);
        setUserSession(userData.api_token);
        setUserRole(userData.user_role);
        setUserMenus(userData.menus);
        setUserPlan(userData.plan);
        setRefreshToken(userData?.refresh_token);

        Axios.interceptors.request.use(function (config) {
          config.headers["Authorization"] = resultData?.data?.api_token
            ? `Bearer ${resultData.data.api_token}`
            : "";
          return config;
        });

        if (resultData.data && resultData.data.is_new_user) {
          history.push(`/${getUserRole()}/home`);
        } else {
          history.push("/");
        }
      } else {
        toast.error("Please Try Agian!");
      }
    }
  };

  const configureAxios = (token) => {
    Axios.interceptors.request.use((config) => {
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      return config;
    });
  };

  const responseGoogleError = () => {
    toast.error("Google login failed. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <GoogleLogin
        onSuccess={responseGoogleSuccess}
        onError={responseGoogleError}
        useOneTap
        text="continue_with"
        logo_alignment="center"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
