import axios from "axios";
import Axios from "../config/axios.js";
import { saveAs } from "file-saver";
class SortResumeService {
  sortResume = async (formdata) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/api-sort-resumes";

    return axios
      .post(url, formdata, { responseType: "blob" }) // Set responseType to 'blob'
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/zip" }); // Use response.data
        saveAs(blob, "sortResume.zip");
      })
      .catch((error) => {
        console.error("Error downloading the file", error);
      });
  };
  getJdbased = async (formdata) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/api-jdresume";

    return axios
      .post(url, formdata, { responseType: "blob" }) // Set responseType to 'blob'
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/zip" }); // Use response.data
        saveAs(blob, "sortResume.zip");
      })
      .catch((error) => {
        console.error("Error downloading the file", error);
      });
  };

  getResumeRanking = async (data) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/resume-ranking";
    
    return await Axios.post(url, data)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
const SortResumeServiceInstance = new SortResumeService();
export default SortResumeServiceInstance;
