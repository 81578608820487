import { combineReducers } from "redux";
import profileReducer from "./profile";
import trainingReducer from "./training";
import colorReducer from "./colorTheme";
import qboardReducer from "./qboard";
import commonReducer from "./common";
import authReducer from "./auth";
import jobReducer from "./job";
import dashboardCommonReducer from "./dashboardCommon";
import reqTrainingReducer from "./reqTraining";
import customUserLinkReducer from "./customUserLink";
import zoomMeetingReducer from "./zoomMeeting";
import courseReducer from "./course";
import myjobReducer from "./myjob";
import CarrerReducer from "./carreerProfile";
import RecruiterAIReducer from "./recruiterAi";

const appReducer = combineReducers({
  profile: profileReducer,
  activetheme: colorReducer,
  training: trainingReducer,
  qboard: qboardReducer,
  common: commonReducer,
  auth: authReducer,
  job: jobReducer,
  dashboardCommon: dashboardCommonReducer,
  reqTraining: reqTrainingReducer,
  customUserLink: customUserLinkReducer,
  zoomMeeting: zoomMeetingReducer,
  course: courseReducer,
  myjob: myjobReducer,
  roadmap: CarrerReducer,
  recruiterAI: RecruiterAIReducer,
});

const reducers = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") state = undefined;

  return appReducer(state, action);
};

export default reducers;
