import Axios from "../config/axios";

class TrainingService {
  getMenus = () => {
    return Axios.post("/get_menus")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createMenus = (menuData) => {
    return Axios.post("/add_menus", { menus: menuData })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllLanguages = () => {
    return Axios.post("/getlanguages")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllLevels = () => {
    return Axios.post("/getlearninglevels")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getTrainingCategory = () => {
    return Axios.post("/get-interests")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCourses = () => {
    return Axios.post("/get_courses")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteCourse = (deleteData) => {
    return Axios.post("/new_delete_course", deleteData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createCourse = (courseData) => {
    return Axios.post("/add_course", courseData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeOrder = (orderData) => {
    return Axios.post("/change_order", orderData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addBatch = (batchData) => {
    return Axios.post("/add_batch", batchData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteSession = (sessionData) => {
    return Axios.post("/delete_session", sessionData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCurrencies = () => {
    return Axios.post("/getcurrencies")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addSession = (sessionData) => {
    return Axios.post("/add_session", sessionData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addAssociate = (associateData) => {
    return Axios.post("/add_associate", associateData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteAssociate = (associateData) => {
    return Axios.post("/delete_associate", associateData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addFinance = (financeData) => {
    return Axios.post("/add_finance", financeData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addFeedback = (feedbackData) => {
    return Axios.post("/add_feedback", feedbackData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  enrollCourse = (data) => {
    return Axios.post("/enroll_user", data)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const trainingServiceInstance = new TrainingService();
export default trainingServiceInstance;
