import React from "react";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { toast } from "react-toastify";

const FacebookLoginComponent = () => {
  const responseFacebookSuccess = (response) => {
    toast.success("Logged in with Facebook!");
  };

  const responseFacebookError = (response) => {
    toast.error("Please Try Again!");
  };

  return (
    <FacebookLogin
      appId="your-facebook-app-id" // Replace with your actual Facebook App ID
      autoLoad={false}
      fields="first_name,last_name,email"
      callback={responseFacebookSuccess}
      onFailure={responseFacebookError}
      render={(renderProps) => (
        <button
          className="btn  d-flex align-content-around justify-content-center"
          onClick={renderProps.onClick}
          disabled={renderProps.isDisabled}
        >
          <span style={{ width: "18px", height: "18px", marginRight: "8px" }}>
            <FontAwesomeIcon
              icon={faFacebookF}
              style={{
                fontSize: "20px", // Adjust the size of the icon
                color: "#0866ff", // Set a color if needed (e.g., Facebook blue)
              }}
            />
          </span>
          <div className="">Continue with Facebook</div>
        </button>
      )}
    />
  );
};

export default FacebookLoginComponent;
