import {
  SET_JOB_POST,
  JOB_POST_COUNT,
  UPDATE_JOB,
  JOB_ERROR,
  JOB_SUCCESS,
  INSERT_JOB,
  DELETE_JOB_POST,
  SET_JOB_CATEGORY,
  SET_JOB_SPECIALIZATION,
  SET_JOB_APPLY,
  JOB_APPLY_DETAILS,
  JOB_AI_DETAILS,
  RESET_JOB_AI_DETAILS, 
  REQ_ACTIVE,
} from '../actions/types';

const initialState = {
	jobPost: [],
  jobPostCount: 0,
  jobFilter: { 
    searchTerm: '',
    star_filter: 0,
    tab_view: '',
    category_id: '',
  },
  jobError:'',
  jobSuccess:'',
  jobCategory: [],
  jobSpecialization: [],
  jobApply: {
    isModalOpen: false,
    requirements_job_apply_requirements_id: '',
    requirements_job_apply_description: '',
  },
  jobApplyDetails: {
    isModalOpen: false,
    appliedDetailsData: [],
  },
  jobAiDetails:{}
}
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_JOB_POST:
      return {
            ...state, 
            jobPost: (payload.isCreation) ? payload.jobPost : state.jobPost.concat(payload.jobPost)
       }

     case INSERT_JOB:
       return { ...state, jobPost:[payload, ...state.jobPost] }
       
     case JOB_POST_COUNT:
      return { ...state, jobPostCount: payload }

     case UPDATE_JOB:
       return {
         ...state, 
         jobPost:
          state.jobPost.map(post => post.requirements_job_id === payload.requirements_job_id ? 
           { ...post, ...payload} : post 
         )
     }
     
     case DELETE_JOB_POST:
      return {
        ...state,
        jobPost:  state.jobPost.filter(post => post.requirements_job_id !== payload.requirements_job_id)
      }

    case SET_JOB_CATEGORY:
      return {
        ...state,
        jobCategory: payload
      }

    case SET_JOB_SPECIALIZATION:
      return {
        ...state,
        jobSpecialization: payload
      }

    case SET_JOB_APPLY:
      return {
        ...state,
        jobApply: payload
      }
     
    case JOB_APPLY_DETAILS: 
      return {
        ...state,
        jobApplyDetails: {...state.jobApplyDetails, ...payload}
      }

    case JOB_SUCCESS:
       return { ...state, jobSuccess: payload }

    case JOB_ERROR:
       return { ...state, jobError: payload }

    case JOB_AI_DETAILS:
        return {
          ...state,
          jobAiDetails: payload
        }
    
    case RESET_JOB_AI_DETAILS:  
      return { 
        ...state, 
        jobAiDetails: {}  
      };

    default:
      return state;
  }
}

export default reducer;