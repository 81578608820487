import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";

import FormLoader from "../../components/FormLoader";
import GoogleLoginComponent from "./GoogleLoginComponent";
import commonService from "../../services/common.service";
import AuthService from "../../services/auth.service";
import { userRegister } from "../../actions/auth";
import { getUser } from "../../utils/UserDetails";
import { months, currentYear } from "../../constants/DefaultValues";

const Register = (props) => {
  const history = useHistory();
  const location = useLocation();
  let daysofMonth = Array.from(Array(32).keys());
  daysofMonth.shift();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRole, setActiveRole] = useState(null);
  const [isOrganisation, setOrganisation] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [displayRole, setDisplayRole] = useState(true);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [selectRole, setSelectRole] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const initialValues = {
    organisation_name: "",
    website: "",
    client_department_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    password: "",
    register_terms: props.isFrom ? true : false,
    birth_month: 1,
    birth_day: daysofMonth[0],
    birth_year: currentYear,
    role_type: activeRole,
    dob: "1/" + daysofMonth[0] + "/" + currentYear,
  };

  useEffect(() => {
    let getYears = [];
    for (var i = currentYear; i > 1920; i--) {
      getYears.push(i);
    }
    setYearList(getYears);
    if (props?.userRole) {
      setActiveRole(props.userRole);
      setDisplayRole(false);
    } else if (props.role) {
      setActiveRole(props.role.toUpperCase());
      setDisplayRole(false);
    } else {
      setActiveRole("TRAINER");
    }
    setCurrentUser(getUser());
  }, []);

  useEffect(() => {
    // Get the URLSearchParams object from the current location
    const queryParams = new URLSearchParams(location.search);
    // Extract the 'role' parameter
    const roleParam = queryParams?.get("role")?.toUpperCase();
    if (roleParam) {
      setActiveRole(roleParam);
      setDisplayRole(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [currentUser]);

  useEffect(() => {
    if (props.activeRole) {
      let roledata = props.isRole ? props.isRole : props.activeRole;
      setActiveRole(roledata);
      setOrganisation(false);

      if (
        props.activeRole === "INSTITUTION" ||
        (props.isRole && props.isRole === "CLIENT")
      ) {
        setOrganisation(true);
      }
    }
  }, [props.activeRole]);

  useEffect(() => {
    if (props.registerError) setErrorMsg(props.registerError);
  }, [props.registerError]);

  useEffect(() => {
    if (props.registerSuccess) {
      setIsModalOpen(true);
    }
    if (props.getClients) {
      props.getClients();
    }
  }, [props.registerSuccess]);

  const checkUniqueEmail = async (email) => {
    let resultdata = await AuthService.checkUniqueEmail(email);

    if (resultdata.status === true) {
      return false;
    } else {
      return true;
    }
  };

  const checkUniquePhone = async (country_code, phone) => {
    let phoneData = {
      country_code: country_code,
      phone: phone,
    };
    let resultdata = await AuthService.checkUniquePhone(phoneData);

    if (resultdata.status === true) {
      return false;
    } else {
      return true;
    }
  };

  const checkUniqueDepartment = async (departmentName) => {
    let department_name = {
      client_department_name: departmentName,
    };

    let resultdata = await commonService.checkUniqueDepartment(department_name);

    if (resultdata.status === true) {
      return false;
    } else {
      return true;
    }
  };

  const setRoleData = () => {
    setActiveRole(selectRole);
  };

  Yup.addMethod(Yup.string, "checkuniqueemail", function (errorMessage) {
    return this.test(`custom-email`, errorMessage, function (value) {
      const { path, createError } = this;
      if (value)
        return (
          checkUniqueEmail(value) ||
          createError({ path, message: errorMessage })
        );
    });
  });

  Yup.addMethod(Yup.string, "checkuniquephone", function (errorMessage) {
    return this.test(`custom-phone`, errorMessage, function (value) {
      const { path, createError, parent } = this;

      if (value)
        return (
          checkUniquePhone(parent.country_code, value) ||
          createError({ path, message: errorMessage })
        );
    });
  });

  Yup.addMethod(Yup.string, "checkuniquedepartment", function (errorMessage) {
    return this.test(`custom-phone`, errorMessage, function (value) {
      const { path, createError, parent } = this;

      if (value)
        return (
          checkUniqueDepartment(value) ||
          createError({ path, message: errorMessage })
        );
    });
  });

  Yup.addMethod(Yup.string, "minAge", function (errorMessage) {
    return this.test(`custom-min-age`, errorMessage, function (value) {
      const { path, createError } = this;

      var today = new Date();
      var birthDate = new Date(value);
      var min = 18;
      var age = today.getFullYear() - birthDate.getFullYear();

      if (age > min + 1) {
        return true;
      }

      var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= min || createError({ path, message: errorMessage });
    });
  });

  const registerSchema = Yup.object().shape({
    role_type: Yup.string().required("Choose your role"),

    organisation_name: isOrganisation
      ? Yup.string().required("Organisation name is required")
      : Yup.string(),

    client_department_name:
      props.isRole === "DEPTADM"
        ? Yup.string()
            .required("Department name is required")
            .checkuniquedepartment("Department Name already taken")
        : Yup.string(),

    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        "Enter valid email",
      )
      .checkuniqueemail("Email has already taken"),
    phone: Yup.string()
      .required("Mobile number is required")
      .checkuniquephone("Mobile number has already taken"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be 8 chars minimum")
      .matches(
        /^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/,
        "atleast 1 uppercase letter, 1 special character and 1 number",
      ),
    dob: Yup.string().minAge("You must be at least 18 years old."),
    register_terms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required",
    ),
  });

  const handleRegister = (
    formData,
    resetForm,
    setSubmitting,
    setFieldValue,
  ) => {
    props.handleRegisterSubmit(
      formData,
      resetForm,
      setSubmitting,
      setFieldValue,
    );
  };

  const formatPhoneNumberOutput = (
    isValid,
    newNumber,
    countryData,
    fullNumber,
    isExtension,
    setFieldValue,
  ) => {
    setFieldValue("phone", newNumber);
    setFieldValue("country_code", "+" + countryData.dialCode);
  };

  const formatCountryOutput = (
    newNumber,
    countryData,
    fullNumber,
    isExtension,
  ) => {
    return "+" + countryData.dialCode;
  };

  return (
    <>
      <div className="shadow-container mt-2 ">
        {props.isFrom ? (
          <></>
        ) : (
          <div className="d-flex justify-content-center register-content">
            <p>Already have an account?</p>
            {/* <Link
              to="#"
              className="mx-4"
              onClick={() => props.setViewLogin(true)}
            >
              Login
            </Link> */}
            <a
              href="#"
              className="mx-4"
              onClick={(e) => {
                e.preventDefault(); // Prevents default anchor behavior
                props.setViewLogin(true);
              }}
            >
              Login
            </a>
          </div>
        )}

        <div className="auth-form">
          <h4>Register</h4>
          {displayRole ? (
            <p>
              Welcome Back to <span className="highlight">T Junction</span>
            </p>
          ) : (
            ""
          )}

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={registerSchema}
            validateOnChange={false}
            onSubmit={(
              formData,
              { resetForm, setSubmitting, setFieldValue },
            ) => {
              setErrorMsg("");
              formData.role_type = props.role ? props.role : activeRole;
              handleRegister(formData, resetForm, setSubmitting, setFieldValue);
            }}
          >
            {(formProps) => {
              const {
                values,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                errors,
                touched,
              } = formProps;

              return (
                <Form>
                  {!props.isFrom ? (
                    <div className="form-group mb-1">
                      <Field
                        name="role_type"
                        as="select"
                        className="form-control form-control-sm col text-center "
                        onChange={(event) => {
                          if (event.target.value !== "INSTITUTION") {
                            setFieldValue("organisation_name", "");
                            setFieldValue("website", "");
                            setOrganisation(false);
                          } else {
                            setOrganisation(true);
                          }
                          setActiveRole(event.target.value);
                          const index = props.masterRoles.findIndex(
                            (role) =>
                              role.roles_master_code == event.target.value,
                          );
                          const obj = props.masterRoles.find(
                            (role, index) =>
                              role.roles_master_code == event.target.value,
                          );
                          props.setRolesActive(obj, index);
                          setFieldValue("role_type", event.target.value);
                        }}
                        disabled={!displayRole}
                      >
                        <option value="">Select role</option>
                        {props.masterRoles.map((roles) => {
                          return (
                            <option value={roles.roles_master_code}>
                              {roles.roles_master_name}
                            </option>
                          );
                        })}
                      </Field>
                      <span className="error-message">
                        {errors.role_type && touched.role_type
                          ? errors.role_type
                          : null}
                      </span>
                    </div>
                  ) : null}

                  {isOrganisation && (
                    <>
                      <div className="form-group mb-1">
                        <Field
                          type="text"
                          name="organisation_name"
                          id="organisation_name"
                          className="form-control form-control-sm"
                          placeholder="Organisation name"
                        />
                        <span className="error-message">
                          {errors.organisation_name && touched.organisation_name
                            ? errors.organisation_name
                            : null}
                        </span>
                      </div>

                      <div className="form-group mb-1">
                        <div className="col">
                          <Field
                            type="text"
                            name="website"
                            id="website"
                            className="form-control form-control-sm"
                            placeholder="Website"
                          />
                          <span className="error-message">
                            {errors.website && touched.website
                              ? errors.website
                              : null}
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {props.isRole && props.isRole === "DEPTADM" && (
                    <div className="form-group mb-1">
                      <Field
                        type="text"
                        name="client_department_name"
                        id="client_department_name"
                        className="form-control form-control-sm"
                        placeholder="Department name"
                      />
                      <span className="error-message">
                        {errors.client_department_name &&
                        touched.client_department_name
                          ? errors.client_department_name
                          : null}
                      </span>
                    </div>
                  )}

                  <div className="form-group mb-1">
                    <div className="row">
                      <div className="col">
                        <Field
                          type="text"
                          name="first_name"
                          id="first_name"
                          className="form-control form-control-sm"
                          placeholder="First name"
                        />
                        <span className="error-message">
                          {errors.first_name && touched.first_name
                            ? errors.first_name
                            : null}
                        </span>
                      </div>
                      <div className="col">
                        <Field
                          type="text"
                          name="last_name"
                          id="last_name"
                          className="form-control form-control-sm"
                          placeholder="Last name"
                        />
                        <span className="error-message">
                          {errors.last_name && touched.last_name
                            ? errors.last_name
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      className="form-control form-control-sm"
                      placeholder="Email"
                    />
                    <span className="error-message">
                      {errors.email && touched.email ? errors.email : null}
                    </span>
                  </div>
                  <div className="form-group mb-1">
                    <IntlTelInput
                      defaultCountry="in"
                      fieldId="phone"
                      fieldName="phone"
                      separateDialCode={true}
                      inputClassName="form-control form-control-sm"
                      autoPlaceholder={false}
                      placeholder="Mobile number"
                      onPhoneNumberBlur={() => {
                        setFieldTouched("phone", true);
                      }}
                      onPhoneNumberChange={(...args) => {
                        formatPhoneNumberOutput(...args, setFieldValue);
                      }}
                      onSelectFlag={(...args) => {
                        setFieldValue(
                          "country_code",
                          formatCountryOutput(...args),
                        );
                      }}
                    />
                    <Field
                      type="hidden"
                      name="country_code"
                      id="country_code"
                      className="form-control form-control-sm"
                      placeholder="country_code"
                    />

                    <span className="error-message d-block">
                      {errors.phone && touched.phone ? errors.phone : null}
                    </span>
                  </div>
                  <div className="form-group mb-1">
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      className="form-control form-control-sm"
                      placeholder="Create password"
                    />
                    <span className="error-message">
                      {errors.password && touched.password
                        ? errors.password
                        : null}
                    </span>
                  </div>

                  <div className="form-group mb-1">
                    <label htmlFor="dob">DOB</label>
                    <div className="row dob">
                      <Field
                        name="birth_month"
                        as="select"
                        className="form-control form-control-sm col text-center mx-2"
                      >
                        {months.map((v, i) => {
                          return <option value={i + 1}> {v} </option>;
                        })}
                      </Field>

                      <Field
                        name="birth_day"
                        as="select"
                        className="form-control form-control-sm col text-center mx-2"
                      >
                        {daysofMonth.map((v) => {
                          return <option value={v}> {v} </option>;
                        })}
                      </Field>

                      <Field
                        name="birth_year"
                        as="select"
                        className="form-control form-control-sm col text-center mx-2"
                      >
                        {yearList.map((v) => {
                          return <option value={v}> {v} </option>;
                        })}
                      </Field>
                    </div>
                    <Field
                      type="hidden"
                      name="dob"
                      id="dob"
                      className="form-control form-control-sm col text-center"
                      value={
                        (values.dob =
                          values.birth_month +
                          "/" +
                          values.birth_day +
                          "/" +
                          values.birth_year)
                      }
                    ></Field>
                    <span className="error-message">
                      {errors.dob && touched.dob ? errors.dob : null}
                    </span>
                  </div>

                  <div className="mb-2">
                    <small>
                      Note: IP address is captured for lawful purposes.
                    </small>
                  </div>

                  {!props.isFrom ? (
                    <div className="form-group">
                      <div className="form-check terms_check">
                        <Field
                          type="checkbox"
                          name="register_terms"
                          className="form-check-input"
                          id="register_terms"
                          onChange={(e) => {
                            setIsChecked(!isChecked);
                            setFieldValue("register_terms", e.target.checked);
                          }}
                        />
                        <p className="form-check-label mb-0">
                          I agree to the{" "}
                          <Link
                            to="#"
                            className="signup-policy"
                            data-href="https://zointvc.com/terms-and-conditions"
                          >
                            Terms and Conditions
                          </Link>{" "}
                          and
                          <Link
                            to="#"
                            className="signup-policy"
                            data-href="https://zointvc.com/privacy-policy"
                          >
                            {" "}
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                      <span className="error-message">
                        {errors.register_terms && touched.register_terms
                          ? errors.register_terms
                          : null}
                      </span>
                    </div>
                  ) : null}

                  <span className="error-message">{errorMsg}</span>

                  <button
                    type="submit"
                    className="text-center btn btn-primary btn-block font-weight-bold"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FormLoader />
                    ) : (
                      <span>{props.isFrom ? props.isFrom : "Register"}</span>
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>

          <div className="social-btn mb-3 ">
            {props.masterRoles && props.masterRoles.length ? (
              <>
                <span className="d-block or-text">or</span>
                <br></br>
                {isChecked ? (
                  <GoogleLoginComponent
                    masterRoles={props.masterRoles}
                    setShowRoleModal={setShowRoleModal}
                    activeRole={activeRole}
                  />
                ) : (
                  <button
                    className="btn  d-flex align-content-around justify-content-center"
                    // to="#"
                    disabled={true}
                  >
                    <span
                      style={{
                        // width: "18px",
                        // height: "18px",
                        // marginRight: "5px",
                        marginBottom: "7px",
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/icon-google-search.svg"
                        }
                        width={18}
                        height={18}
                      />
                    </span>
                    <div style={{ marginBottom: 5, paddingLeft: 0 }}>
                      Continue with Google
                    </div>
                  </button>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* Sucess Modal */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Check the registered mail
          </Modal.Title>
        </Modal.Header>

        <div className="text-center px-0 mx-2">
          {props.isFrom ? (
            <div className="text-center px-0 mx-2">
              Verification email is sent to your {props.isRole.toLowerCase()}.
              Please check Spam folder in case you have not received it in your
              inbox.
            </div>
          ) : (
            <div className="text-center px-0 mx-2">
              Verification email is sent. Please check Spam folder in case you
              have not received it in your inbox.
            </div>
          )}
        </div>

        <Modal.Footer className=" pb-3 mb-0 justify-content-center">
          <button
            type="button"
            className="btn mb-button mb-button-confirm px-5"
            onClick={() => setIsModalOpen(false)}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRoleModal}
        onHide={() => setShowRoleModal(false)}
        backdrop="static"
        keyboard={false}
        className="tj-modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.masterRoles && props.masterRoles.length ? (
            <div className="container p-4 text-center">
              <div className="form-group mb-1">
                <h6>Kindly select your role to continue.</h6>
                <select
                  name="role_type"
                  onChange={(e) => setSelectRole(e.target.value)}
                >
                  <option value="">Select role</option>
                  {props.masterRoles.map((roles) => {
                    return (
                      <option value={roles.roles_master_code}>
                        {roles.roles_master_name}
                      </option>
                    );
                  })}
                </select>

                <button
                  className="btn btn-primary btn-sm mx-auto mt-4 d-block"
                  onClick={setRoleData}
                >
                  {activeRole ? <FormLoader /> : <span>Continue</span>}
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  registerSuccess: state.auth.registerSuccess,
  registerError: state.auth.registerError,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleRegisterSubmit: (formData, resetForm, setSubmitting, setFieldValue) =>
      dispatch(userRegister(formData, resetForm, setSubmitting, setFieldValue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
