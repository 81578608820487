import React, { useState, useEffect, Suspense } from "react";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  jobPostData,
  setJobSuccess,
  setJobError,
} from "../../../../../actions/job";
import { getUser, getUserMenus } from "../../../../../utils/UserDetails";
import LoginModal from "../../../../AllUser/LoginModal";

const JobDescriptionPreview = (props) => {
  getUserMenus();

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.jobSuccess) {
      toast.success(props.jobSuccess);
      props.setJobSuccess("");
      history.push("/recruiter/home?post=jd");
    }
  }, [props.jobSuccess]);

  useEffect(() => {
    if (props.jobError) {
      toast.error(props.jobError);
      props.setJobError("");
    }
  }, [props.jobError]);

  const handleBackClick = () => {
    props.setIsJdView(true);
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);

  const handleJobPost = () => {
    if (getUser()) {
      props.jobPostData({
        requirements_job_description: props.jdData,
        requirements_job_source_description: props.jdText,
      });
    } else {
      handleShow();
    }
  };

  return (
    <>
      <div className="job-description-processing">
        <div className="container">
          <div className="center-section">
            <h5
              className={`section-title ${props.activeColor}-font text-center`}
            >
              Job description preview
            </h5>
            <div className="center-sec">
              {props.jdData?.length > 0 &&
                props.jdData.map((obj, index) => {
                  return (
                    <>
                      <p className="heading">{obj?.criterion_name + ":"}</p>
                      <p className="content">{obj?.criterion_value}</p>
                    </>
                  );
                })}
            </div>
            <div className="button-container d-flex justify-content-end mr-50">
              <button className={`btn`} onClick={handleBackClick}>
                Back
              </button>
              <button
                className={`btn mx-2 btn-${props.activeColor}`}
                onClick={handleJobPost}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  jobSuccess: state.job.jobSuccess,
  jobError: state.job.jobError,
});

export default connect(mapStateToProps, {
  jobPostData,
  setJobError,
  setJobSuccess,
  getUserMenus,
})(JobDescriptionPreview);
