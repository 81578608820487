import { SET_SORTRESUME, RESUME_RANKING } from "./types";
import recruiterAi from "../services/recruiterAi";
import { toast } from "react-toastify";

export const getSortResume =
  (data, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const resultData = await recruiterAi.sortResume(data);

      if (resultData?.status) {
        setSubmitting(false);
        resetForm();
        localStorage.setItem(
          "sort-resume",
          JSON.stringify({
            source: "sort-resume",
            limit: 2,
          }),
        );
        dispatch({
          type: SET_SORTRESUME,
          payload: resultData.data,
        });
      } else {
        toast.error("Please Try Again");
        dispatch({
          type: SET_SORTRESUME,
          payload: resultData.data,
        });
      }
    } catch (err) {
      toast.error("Please Try Again");
      setSubmitting(false);
      resetForm();
      console.log(err);
    }
  };

export const getJdbased =
  (data, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const resultData = await recruiterAi.getJdbased(data);

      if (resultData?.status) {
        setSubmitting(false);
        resetForm();
        localStorage.setItem(
          "sort-resume",
          JSON.stringify({
            source: "sort-resume",
            limit: 2,
          }),
        );
      } else {
        toast.error("Please Try Again");
      }

      dispatch({
        type: SET_SORTRESUME,
        payload: resultData.data,
      });
    } catch (err) {
      setSubmitting(false);
      resetForm();
      toast.error("Please Try Again");
      console.log(err);
    }
  };

export const getResumeRanking =
  (data, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const resultData = await recruiterAi.getResumeRanking(data);

      if (resultData?.status) {
        localStorage.setItem(
          "limitforRR",
          JSON.stringify({
            source: "resume-ranking",
            limit: 2,
          }),
        );
        dispatch({
          type: RESUME_RANKING,
          payload: resultData,
        });
      } else {
        toast.error("Please Try Again");
        dispatch({
          type: RESUME_RANKING,
          payload: resultData,
        });
      }

      if (setSubmitting) {
        setSubmitting(false);
      }

      resetForm();
    } catch (err) {
      toast.error("Please Try Again");

      if (setSubmitting) {
        setSubmitting(false);
      }
      resetForm();
    }
  };
