import React, { Suspense, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  faBriefcase,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, Card } from "react-bootstrap";

import RecruiterHeader from "../../components/layout/RecruiterHeader";
import CareerFooter from "../../components/layout/careerFooter";
import {
  getLimitforEnhanceJD,
  getLimitforRR,
  getLimitforSortResume,
} from "../../utils/UserDetails";
import RegisterModal from "../Student Landing/studentCareer/RegisterModal";

const RecruiterPage = (props) => {
  const history = useHistory();

  const [hoveredCard, setHoveredCard] = useState(false);
  const [hoveredCard1, setHoveredCard1] = useState(false);
  const [hoveredCard2, setHoveredCard2] = useState(false);
  const [hoveredCard3, setHoveredCard3] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [activeTab, setActiveTab] = useState("pag1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleClick = () => {
    if (getLimitforEnhanceJD()?.source === "limitEnchanceJD") {
      setDisplayModal(true);
    } else {
      history.push("/explore/recruiter?site=enjd&role=recruiter");
    }
  };

  const handleClickRR = () => {
    if (getLimitforRR()?.source === "limitforRR") {
      setDisplayModal(true);
    } else {
      history.push("/resume-ranking?role=recruiter");
    }
  };

  const handleRegModal = () => setDisplayModal(false);

  const handleSortResume = () => {
    if (getLimitforSortResume()?.source === "sort-resume") {
      setDisplayModal(true);
    } else {
      history.push("/sort-resume?role=recruiter");
    }
  };

  return (
    <>
      <RecruiterHeader />
      <div
        className="cov-img"
        style={{
          backgroundImage: `url('/assets/img/recr.jpg')`,
        }}
      >
        <div className="overlay">
          <div className="page-container">
            <div className="content-wrapper">
              <h1 className="main-title">Uncover Talent. Empower Careers.</h1>
              <p className="main-description">
                Your partner in talent acquisition, providing a seamless
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="background-css mt-50">
        <div className="container">
          <div className="course-container">
            <h3 className="course-container-title">Delve Deeper</h3>
            <div className="course-card-cont">
              <Card
                className={`${
                  hoveredCard2 === true
                    ? "course-content-font-rec"
                    : "course-title-font-rec"
                } course-card3 col-md-6 col-sm-8 col-10 col-lg-4`}
                onMouseEnter={() => setHoveredCard2(true)}
                onMouseLeave={() => setHoveredCard2(false)}
                onClick={() => handleClick()}
              >
                {hoveredCard2 === true ? (
                  <div>
                    <h5 className="hover-content-title">
                      Create & Enhance JD with AI
                    </h5>
                    <p className="mb-0">
                      Our system prioritizes resources based on the specific
                      roles of employees, ensuring that they have easy access to
                      the information and tools they need to be successful. This
                      streamlined approach enhances productivity and efficiency
                      within the organization.
                    </p>
                  </div>
                ) : (
                  "Create & Enhance JD with AI"
                )}
              </Card>
              <Card
                className={`${
                  hoveredCard === true
                    ? "course-content-font-rec"
                    : "course-title-font-rec"
                } course-card1 col-md-6 col-sm-8 col-10 col-lg-4`}
                onMouseEnter={() => setHoveredCard(true)}
                onMouseLeave={() => setHoveredCard(false)}
                onClick={() => handleSortResume()}
              >
                {hoveredCard === true ? (
                  <div>
                    <h5 className="hover-content-title">
                      Sort resumes based on job roles / skills (AI)
                    </h5>
                    <p className="mb-0">
                      To attract top talent, craft compelling job posts that
                      accurately reflect the role and company culture. Highlight
                      key responsibilities, qualifications, and benefits. Use
                      strong action verbs and keywords to optimize for search
                      engines.
                    </p>
                  </div>
                ) : (
                  "Sort resumes based on job roles / skills (AI)"
                )}
              </Card>
              <Card
                className={`${
                  hoveredCard1 === true
                    ? "course-content-font-rec"
                    : "course-title-font-rec"
                } course-card2 col-md-6 col-sm-8 col-10 col-lg-4`}
                onMouseEnter={() => setHoveredCard1(true)}
                onMouseLeave={() => setHoveredCard1(false)}
                onClick={() => handleClickRR()}
              >
                {hoveredCard1 === true ? (
                  <div>
                    <h5 className="hover-content-title">
                      Resume ranking based on JD (AI)
                    </h5>
                    <p className="mb-0">
                      Enhance your job postings to attract top talent. Craft
                      compelling descriptions that highlight key
                      responsibilities and qualifications. Use strong action
                      verbs and keywords to improve visibility. Optimize your
                      postings for search engines to reach a wider audience.
                    </p>
                  </div>
                ) : (
                  "Resume ranking based on JD (AI)"
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f4f4f4" }}>
        <div className="container">
          <div className="course-container">
            <div className="explore-more-container">
              <h3 className="explore-more-title">
                Discover More About TJunction
              </h3>
              <div className="container px-0">
                <div className="vertical-tabs">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "pag1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("pag1")}
                        role="tab"
                        aria-controls="home"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          style={{
                            width: "20px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        />
                        QBoard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "pag3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("pag3")}
                        role="tab"
                        aria-controls="messages"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FontAwesomeIcon
                          icon={faChalkboardTeacher}
                          style={{
                            width: "20px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        />
                        Trainings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "pag4" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("pag4")}
                        role="tab"
                        aria-controls="settings"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          style={{
                            width: "20px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        />
                        Jobs
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${
                        activeTab === "pag1" ? "active" : ""
                      }`}
                      id="pag1"
                      role="tabpanel"
                    >
                      <div className="sv-tab-panel">
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={faBriefcase}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "5px",
                            }}
                          />
                          <h3>Qboard</h3>
                        </div>
                        <p>
                          Need answers? Post your questions on our dedicated
                          query board. Our team of experts is here to provide
                          clear and timely responses to all your
                          recruiting-related inquiries. From technical queries
                          to best practices, we've got you covered. Ask away and
                          get the answers you need to succeed.
                        </p>
                      </div>
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "pag3" ? "active" : ""
                      }`}
                      id="pag3"
                      role="tabpanel"
                    >
                      <div className="sv-tab-panel">
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={faChalkboardTeacher}
                            style={{
                              width: "20px",
                              height: "30px",
                              marginRight: "5px",
                            }}
                          />
                          <h3>Trainings</h3>
                        </div>
                        <p>
                          Expand your knowledge and skills with our
                          comprehensive training library. Access a wide range of
                          courses on recruitment, industry trends, and personal
                          development. Stay ahead of the curve and enhance your
                          career with our expert-led training programs.
                        </p>
                      </div>
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "pag4" ? "active" : ""
                      }`}
                      id="pag4"
                      role="tabpanel"
                    >
                      <div className="sv-tab-panel">
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={faBriefcase}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "5px",
                            }}
                          />
                          <h3>Jobs</h3>
                        </div>
                        <p>
                          Find and apply for exciting job opportunities tailored
                          to your expertise. Our platform connects recruiters
                          with top talent from various industries. Browse
                          through a curated list of job openings, filter by your
                          preferences, and submit your application directly.
                          Discover your next career move today!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="caro" style={{}}>
        <h3 className="course-container-title pt-3">Voice of Recruiters</h3>
        <Card
          style={{
            width: "80%",
            margin: "auto",
            height: "auto",
            backgroundColor: "#FFFF",
          }}
        >
          <Card>
            <Carousel indicators={true} controls={false}>
              <Carousel.Item>
                <div className="crd-content">
                  <p>
                    “This platform has been a game-changer for our recruitment
                    process. We easily find candidates with verified skills that
                    align with our job requirements. The quality and relevance
                    of applicants have drastically improved.”
                  </p>
                  <div className="d-flex justify-content-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/carousel-img/profile-pic1.svg"
                      }
                      className="test-image"
                      alt="Katrina Peter"
                    />
                    <div className="prof">
                      <h6 className="">Katrina Peter</h6>
                      <p className="">HR Recruiter</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="crd-content">
                  <p>
                    “We’ve hired several candidates through this platform, and
                    they’ve all been job-ready from day one. Their hands-on
                    experience makes onboarding smoother and faster. It’s clear
                    that the platform prepares them well for real-world
                    challenges.”
                  </p>
                  <div className="d-flex justify-content-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/carousel-img/profile-pic2.svg"
                      }
                      className="test-image"
                      alt="Katrina Peter"
                    />
                    <div className="prof">
                      <h6 className="">Suresh Shetty</h6>
                      <p className="">Senior Recruiter</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="crd-content">
                  <p>
                    “The recruitment process is now much more efficient thanks
                    to this platform. We can quickly connect with top talent,
                    reducing the time spent on sourcing and screening. It has
                    helped us hire highly qualified candidates faster than
                    ever.”
                  </p>
                  <div className="d-flex justify-content-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/carousel-img/profile-pic3.svg"
                      }
                      className="test-image"
                      alt="Katrina Peter"
                    />
                    <div className="prof">
                      <h6 className="">Robert Mathew</h6>
                      <p className="">Recruitement Specialist</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Card>
        </Card>
      </div>

      <div style={{ backgroundColor: "#09325b" }}>
        <div className="container">
          <CareerFooter />
        </div>
      </div>

      <Suspense>
        {" "}
        <RegisterModal
          show={displayModal}
          handleClose={handleRegModal}
          userRole="RECRUITER" //recruiter role selected in register modal
        />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, null)(RecruiterPage);
